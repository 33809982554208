<template>
  <div>
    <header>
      <div class="logo">
        <p class="logoimg"></p>
        <p class="logoname">Súper Préstamo</p>
      </div>

      <nav>
        <a href="#home" @click="hash='home'" :class="hash=='home'?'boxLine':''">Inicio</a>
        <a
          href="#product"
          @click="hash='product'"
          :class="hash=='product'?'boxLine':''"
        >Sobre nosotros</a>
       
        <a
          href="#privacy"
          @click="hash='privacy'"
          :class="hash=='privacy'?'boxLine':''"
        >Política de privacidad</a>
        <!-- <a href="#features">Características</a> -->
      </nav>
    </header>
    <div class="home" id="home">
      <div class="mytitle">
        <div class="title0"></div>
        <div class="title1">
          Logra un préstamo de
          <br />hasta
          <span style="color:#FCEC3E">$ 10,000</span>
        </div>
      </div>
      <a
        target="view_window"
        href="http://play.google.com/store/apps/details?id=mx.sup.perfecto"
        class="googlelogo"
      ></a>
    </div>
    <div class="product" id="product">
      <p class="title">Nuestra ventaja</p>
      <div class="productlist">
        <div class="productitem">
          <img src="../assets/images/1@3x.png" alt />
          <div class="productTitle">Alto monto del préstamo</div>
          <p class="itemmsg">Préstamos de hasta 10.000 pesos están disponibles</p>
        
        </div>
        <div style="margin: 0 70px" class="productitem">
          <img src="../assets/images/2@3x.png" alt />
          <div class="productTitle">Solo CURP</div>
          <p class="itemmsg">
           Tasa de aprobación alta transferir rápidamente
          </p>
        </div>
        <div class="productitem">
          <img src="../assets/images/3@3x.png" alt />
          <div class="productTitle">Seguro y confiable</div>
          <p
            class="itemmsg"
          >100% mantenga sus datos seguros. Nunca divulgaremos sus datos a terceros sin su permiso</p>
        </div>
      </div>
    </div>

     <!-- <div class="product" id="product2">
      <p class="title">Proceso de préstamo</p>
      <div class="productlist">
        <div class="productitem2">
          <img src="../assets/images/1.png" alt />
          <div class="productTitle">Instalar la APP</div>
          <p class="itemmsg">Consequi en </p>
          <a href="http://play.google.com/store/apps/details?id=mx.sup.perfecto"  style="color:#43c570;font-size:14px">Google Play</a>
        </div>
        <div  class="productitem2">
          <img src="../assets/images/2.png" alt />
          <div class="productTitle">Registrarte</div>
          <p class="itemmsg">
           Ingresar los datos <br> como lo solicita la APP
          </p>
        </div>
        <div class="productitem2">
          <img src="../assets/images/3.png" alt />
          <div class="productTitle">Realizar la verificación facial</div>
          <p
            class="itemmsg"
          >Realizar la verificación facial después<br> de ingresar los datos</p>
        </div>
        <div class="productitem2">
          <img src="../assets/images/4.png" alt />
          <div class="productTitle">Lograr el préstamo</div>
          <p
            class="itemmsg"
          >Esperar la notificación de <br> confirmación de depósito</p>
        </div>
      </div>
    </div> -->

    <div class="privacy" id="privacy">

      <div :class="!showmore ? 'privacymsg showmore' : 'privacymsg'">
        <p class="title">Política de privacidad</p>
        Súper Préstamo fue desarrollado por ming cheng. Si usted es nuestro usuario, lea atentamente esta Política de
    privacidad para obtener más información sobre cómo recopilamos, usamos, almacenamos y protegemos su información.
    Su privacidad es muy importante para nuestros servicios de aplicaciones y haremos todo lo posible para proteger
    su información personal. Lea detenidamente el contenido de esta Política de privacidad antes de utilizar nuestra
    aplicación.<br /><br />

    <strong>Información personal que recopilamos:</strong> <br />
    <div style="background-color: yellow;">
      <strong >1.  Información SMS:</strong> Súper Préstamo necesita acceder a la información de los SMS del usuario (incluyendo: título del SMS, contenido del SMS, si se lee o no) cuando el usuario contrata un préstamo, para evaluar el valor del usuario y para el análisis antifraude; Súper Préstamo se toma muy en serio la seguridad de los datos del usuario y la plataforma sube los datos del usuario a https://ri.rizjyobo. com/riz-gp/ y se almacena mediante encriptación DES y AES para garantizar la seguridad de los datos del usuario. Súper Préstamo nunca compartirá la información del usuario con terceros.
     </div>   

<strong style="background-color: yellow;">2. Información de la lista de contactos:</strong> Súper Préstamo está obligado a recopilar la Información de la lista de contactos del usuario (incluyendo el nombre y el número de teléfono móvil) cuando éste solicita un préstamo. Se utiliza para identificar si el usuario es auténtico o no. Los datos de la libreta de direcciones se almacenan con encriptación des y AES y se suben a <strong style="background-color: yellow;">https://ri.rizjyobo.com/riz-gp/</strong> después de la encriptación. Para garantizar la seguridad de los datos, esta información no se comparte con terceros.<br />
3. Información de identificación personal: cuando se registra y utiliza nuestros servicios, es posible que le solicitemos que nos proporcione cierta información de identificación personal, que incluye, entre otros, nombre, edad, número de identificación, etc.<br />
   
    4. Lista de aplicaciones instaladas:Para garantizar la seguridad  financiera, es necesario recoger toda la información de instalación de aplicaciones del usuario .<br />

    5. Información del dispositivo: cuando utiliza nuestra APLICACIÓN, necesitamos recopilar el tipo de dispositivo móvil, la identificación del dispositivo, la dirección IP, el SSAID, la dirección MAC, la versión del hardware, la versión del sistema operativo, el IMEI y otra información.<br />

    6.Información de la cámara: autorice la información de su cámara para ayudarnos a verificar su identidad.<br />

    7.Información del directorio de archivos: acceda a la información de su directorio de archivos, como sus imágenes, videos, etc., para identificar riesgos potenciales.<br />

    8. Espacio de almacenamiento: recopile y almacene su información, incluidas sus fotos, videos, etc., para identificar riesgos potenciales.<br />

    9 Ubicación geográfica: Cuando utilice nuestra aplicación, accederemos a su ubicación personal para brindarle un mejor servicio.<br />

    10. Información financiera: Protegeremos la información sobre su cuenta financiera personal o su cuenta utilizando otros instrumentos de pago en relación con nuestros Servicios.<br />

    11. Permisos de lectura y escritura del calendario: cuando utiliza el servicio de préstamo, puede administrar fácilmente su cronograma de pago, establecer recordatorios de pago y suscribirse a recordatorios de eventos al habilitar los permisos del calendario. Si no habilita este permiso, no podrá usar las funciones relacionadas con la administración del calendario, pero no afectará su uso de otros servicios.<br />
    <strong>Finalidad de la recogida de esta información:</strong> <br />
    1. Evalúe su crédito personal para determinar si es elegible para un préstamo
    <br />
    2. Para la evaluación de riesgos potenciales
    <br />
    3. Agiliza el proceso de préstamo
    <br />
    4. Para el análisis de datos para mejorar la calidad de nuestro servicio
    <br />
    <strong>Cómo usamos esta información:</strong> <br />
    Toda la información del usuario que recopilamos se utiliza en circunstancias legítimas y razonables, y esta
    información se utilizará para brindar a los usuarios mejores servicios personalizados. Toda la información
    personal no se compartirá con terceros sin su consentimiento.<br />
    <strong>Almacenamiento y Protección de Información Personal:</strong> <br />
    Subimos la información personal recopilada a nuestro centro de almacenamiento de datos
    (https://ri.rizjyobo.com/riz-gp/). Utilizamos medidas de seguridad físicas, técnicas y administrativas
    razonables para proteger la información que recopilamos. Si nos damos cuenta de una violación de seguridad que
    resulta en la divulgación no autorizada de su información de registro, podemos intentar notificarle
    electrónicamente a través de los Servicios para que pueda tomar las medidas de seguridad adecuadas.
    <br />
    <strong>Tus derechos:</strong> <br />Puede ver su información personal a través de nuestra aplicación y, para
    garantizar la exactitud de sus datos personales, puede enviarnos solicitudes para cambiar, agregar, actualizar y
    eliminar datos personales obtenidos o enviados previamente.<br />
    <strong>Cambios a esta política:</strong> <br />Nos reservamos el derecho de realizar cambios a esta Política de
    privacidad en cualquier momento y tener la última palabra sobre cualquier conflicto de versión, y publicaremos
    los cambios a esta Política de privacidad en nuestra plataforma. Tiene derecho a conocer cualquier actualización
    que hagamos a esta Política de privacidad, por lo que le recomendamos que revise esta Política de privacidad con
    frecuencia para evitar cualquier inconveniente causado por las actualizaciones de esta Política de
    privacidad.<br />
    <strong>Contáctenos:</strong> <br />Si tiene alguna pregunta sobre esta Política de privacidad, comuníquese con nosotros a la siguiente dirección de correo electrónico: dfdtet4fgh@gmail.com.



    <br />
        <p v-show="showmore" @click="showmore=!showmore" class="show"></p>
      </div>
    </div>
    <footer>
      <!-- <p class="title">Contact Us</p> -->
      <div class="contact">
        <div>
          <img width="25px" src="../assets/images/phone.png" alt />
          5156867204
        </div>
        <div>
          <img width="25px" src="../assets/images/email.png" alt />
          dfdtet4fgh@gmail.com
        </div>
        <div>
          <img width="25px" style="float:left" src="../assets/images/address.png" alt />
          <p style="float:left">NL 65, Rancho Carvajal, N.L.</p>
        </div>
      </div>
      <p class="end">@Copyright by Súper Préstamo</p>
    </footer>
  </div>
</template>
<style lang="less">
a {
  text-decoration: none;
}
.boxLine {
  color: #43c570;
  // border-bottom: 5px solid #43c570;
}
.title {
  padding: 20px 0;
  font-family: Helvetica-Bold;
  font-size: 24px;
  color: #333333;
  text-align: center;
  font-weight: 600;
}

header {
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000000000000;
  line-height: 80px;
  box-shadow: 0 0 5px 2px #ccc;

  .logo {
    margin-left: 167px;
    float: left;

    .logoimg {
      margin-top: 15px;
      width: 48px;
      height: 48px;
      background: url(../assets/images/logo@3x.png) no-repeat;
      background-size: 100%;
      float: left;
    }

    .logoname {
      font-family: Helvetica-Bold;
      font-size: 14px;
      color: #333333;
      float: left;
      margin-left: 20px;
    }
  }

  nav {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 167px;

    a {
      margin: 0 40px;
      font-family: Helvetica-Bold;
      font-size: 18px;
      color: #333333;
      text-align: left;
      text-align: center;
      /* border-bottom: 2px solid #fff; */
    }

    a:hover {
      color: #43c570;

      transition: 0.3s;
    }
  }
}

.home {
  background: url(../assets/images/banner.png) no-repeat;
  background-size: 100%;
  width: 100%;
  height: 600px;
  overflow: hidden;
  margin-top: 70px;

  img {
    width: 700px;
    margin-top: 250px;
    margin-left: 150px;
  }

  .lbr {
    width: 80px;
    height: 16px;
    background-color: #fff;
    margin: 60px 0;
    margin-left: 150px;
  }

  .googlelogo {
    display: block;
    background: url(../assets/images/Googleplay.png) no-repeat;
    background-size: 100%;
    width: 180px;
    height: 215px;
    margin-top: 80px;
    margin-left: 250px;
  }

  .mytitle {
    width: 700px;
    margin-top: 100px;
    margin-left: 250px;

    .title0 {
      font-size: 60px;
      font-family: Baloo2-ExtraBold, Baloo2;
      font-weight: 800;
      color: #37af70;
      line-height: 80px;
      margin-bottom: 50px;
    }

    .title1 {
      font-size: 40px;
      font-family: Baloo2-ExtraBold, Baloo2;
      font-weight: 800;
      color: #fff;
      line-height: 50px;
      margin-bottom: 50px;
    }
  }
}

.product {
  .productlist {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 410px;

    .productitem {
      float: left;
      font-size: 16px;
      text-align: center;
      width: 460px;
      height: 300px;
      padding: 20px;
      background-color: #fff;
      box-shadow: 0 2px 30px 0 rgba(220, 220, 220, 0.5);
      border-radius: 30px;
      .productTitle {
        color: #37af70;
        margin:20px 0;
      }
      img {
        width: 100px;
        height: 100px;
        margin-top: 30px;
      }

      .itemmsg {
        font-family: Helvetica;
        font-size: 16px;
        color: #333333;
        text-align: center;
        line-height: 22px;
        margin-top: 10px;
      }
    }

    .productitem:hover {
      transform: scale(1.1);
    }
        .productitem2 {
      float: left;
      font-size: 16px;
      text-align: center;
      width: 460px;
      height: 300px;
      padding: 20px;
      background-color: #fff;
    
      border-radius: 30px;
      .productTitle {
        color: #37af70;
        margin:20px 0;
      }
      img {
        width: 100px;
        height: 100px;
        margin-top: 30px;
      }

      .itemmsg {
        font-family: Helvetica;
        font-size: 16px;
        color: #333333;
        text-align: center;
        line-height: 22px;
        margin-top: 10px;
      }
    }
  }
}

.company {
  padding: 0 280px;
  margin-top: 180px;

  .companylist {
    .companyitem {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 400px;

      .left {
        img {
          width: 586px;
          height: 414px;
        }
      }

      .right {
        .msg {
          max-width: 540px;
          font-family: Helvetica;
          font-size: 20px;
          color: #333333;
          text-align: left;
          line-height: 60px;
        }
      }
    }
  }
}

.features {
  padding: 0 200px;
  margin-top: 180px;

  .companyitem {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    margin-bottom: 300px;

    .left {
      img {
        width: 586px;
        height: 414px;
      }
    }

    .right {
      .msg {
        max-width: 800px;
        font-family: Helvetica;
        font-size: 20px;
        color: #666666;
        text-align: left;
        line-height: 60px;
      }
    }
  }
}

.about {
  height: 500px;
  padding: 80px 180px;
  background: #43c570;
  text-align: center;

  .title {
    font-family: Helvetica-Bold;
    font-size: 44px;
    color: #ffffff;
    padding: 0;
    padding-bottom: 60px;
  }

  .aboutmsg {
    font-family: Helvetica;
    font-size: 24px;
    color: #ffffff;
    line-height: 60px;
    text-align: left;
  }
}

.privacy {
  padding: 0px 210px;
  padding-bottom: 100px;
  position: relative;

  .title {
    padding: 80px 0;
    font-weight: 600;
    font-size: 16;
  }

  strong {
    font-weight: 600;
    font-size: 16;
  }

  .privacymsg {
    font-family: Helvetica;
    font-size: 20px;
    height: 750px;
    overflow: hidden;
    text-align: left;
    line-height: 50px;

    p {
      font-weight: 600;
      font-size: 22px;
      margin-top: 40px;
    }

    .show {
      background: url(../assets/images/getMore.png) no-repeat;
      background-size: 100%;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 750px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .showmore {
    height: auto;
    overflow: auto;
  }
}

footer {
  height: 180px;
  background: #2f2f2f;
  color: #fff;

  .title {
    color: #fff;
    padding: 80px 0;
  }

  .contact {
    padding-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    color: #ffffff;
    text-align: left;

    div {
      font-family: Helvetica;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 31px;
      width: 33%;
      img {
        margin-right: 5px;
      }
    }
  }

  .end {
      width: 100%;
    text-align: center;
    font-family: Helvetica;
    font-size: 16px;
    color: #ffffff;
    background: #1A1A1A;
    height: 80px;
    line-height: 80px;
    margin-top: 60px;
  }
}
</style>
<script>
export default {
  name: 'Home',
  data() {
    return {
      showmore: true,
      hash: 'home',
    }
  },
}
</script>